<template>
  <div style="padding-top: 50px">
    <div class="list-box">
      <div class="view-content">
        <div class="list-nav">
          <h4>新闻分类</h4>
          <div class="tags">
        <span
          class="all"
          :class="cate_id === all_cate ? 'active' : ''"
          @click="setCate(all_cate)"
        >所有</span
        >
            <span
              @click="setCate(item.id)"
              v-for="item in cate"
              :key="item.id"
              :class="item.id === cate_id ? 'active' : ''"
            >{{ item.title }}</span
            >
          </div>
        </div>
        <div class="list-article">



          <article
            :title="item.title"
            v-for="item in data"
            :key="item.id"
          >
            <router-link :to="{path:'/news/item',query: {id: item.id}}">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="summary">
                {{ item.summary }}
              </div>
              <div class="meta">
                <span class="author">
                  {{ item.author ? item.author : '管理员'  }}
                </span>
                <span class="date">
                  {{ item.create_time}}
                </span>
                <span class="tag">
                  {{ item.cate_title }}
                </span>

              </div>
              <img :src="item.cover" :title="item.title" :alt="item.title" />
            </router-link>
          </article>





        </div>
        <div class="more" @click="more()">
          <span>查看更多</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "vue3-eventbus";
import article from "../../api/article";
import state from "../../store/state";
import { message } from "ant-design-vue/es";

export default {
  name: "NewsList",
  data(){
    return {
      cate: [],
      page: 1,
      limit: 6,
      data: [],
      cate_id: 1,
      all_cate: 1,
    };
  },
  mounted() {
    document.title = "新闻中心 - "+state.websiteSet.website.title

    const that = this;
    eventBus.on("news_cate", (val) => {
      that.cate = val;
      this.all_cate = val[0].pid;
    });
    this.getArticles();
  },
  methods: {
    //获取12个文章
    getArticles() {
      const that = this;
      article
        .getArticles(
          state.websiteSet.website.id,
          that.cate_id,
          that.page,
          that.limit
        )
        .then((res) => {
          if (res.code === 0 && res.data.data.length > 0) {
            let data = res.data.data;
            data.forEach(function(item) {
              that.data.push(item);
            });
            that.page = that.page + 1;
          } else {
            message.warning("没有更多了");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setCate(cate_id) {
      this.cate_id = cate_id;
      this.page = 1;
      this.data = [];
      this.getArticles();
    },
    more() {
      this.getArticles();
    }
  }
};
</script>

<style scoped>
.list-box {
  padding: 60px 0 100px;
}
.view-content {
  position: relative;
  width: 630px;
  margin: 0 auto;
  padding: 0;
}
.list-nav {
  position: absolute;
  top: 0;
  left: 0;
}
.list-nav h4 {
  color: #000f16;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}
.tags{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.tags span{
  border: 1px solid #000;
  cursor: pointer;
  font-weight: 100;
  font-size: 12px;
  height: 25px;
  line-height: 25px;
  margin-top: 14px;
  text-align: center;
  width: 78px;
}
.tags .active{
  background: #00263c;
  color: #FFFFFF;
}
.list-article{
  position: relative;
  left: 200px;
}
article{
  margin-top: 20px;
  padding: 20px 0 10px;
  border-top: 1px solid #dadcde;
}
article a{
  text-decoration: none;
  font-weight: 300;
  cursor: pointer;
  position: relative;
  display: block;
  padding-right: 140px;
  padding-left: 14px;
}
article .title{
  font-size: 22px;
  margin-bottom: 15px;
  color: #000F16;
  font-weight: 300;
}
article .summary{
  font-size: 12px;
  color: #939799;
  line-height: 22px;
  font-weight: 400;
}
article .meta{
  font-size: 12px;
  color: #54585A;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 10px;
}
article .meta .author,article .meta .date{
  margin-right: 30px;
}
article img{
  width: 102px;
  height: 102px;
  right: 20px;
  object-fit: cover;
  position: absolute;
  top: 0;
}
.more{
  position: absolute;
  left: 200px;
  text-align: center;
  width: 100%;
  margin: 40px auto;
}
.more span{
  border: 1px solid #000;
  cursor: pointer;
  height: 25px;
  line-height: 25px;
  margin-top: 14px;
  text-align: center;
  padding: 15px 40px;
  font-size: 12px;
  font-weight: bold;
}
</style>
